.tableFixHead {
    overflow: auto;
    height: 100vh;
}

.tableFixHead thead th {
    position: sticky;
    top: 0;
    z-index: 1;
}

/* Just common table stuff. Really. */
table {
    border-collapse: collapse;
    width: 100%;
}

th,
td {
    padding: 8px 16px;
}

th {
    background: #eee;
}
.logo-image {
    width: 200px;
    height: auto;
    border: 1px solid #ccc;
}
.image-container {
    width: 35px; 
    height: 35px;
    object-fit: cover; 
  }

  .hr-lines{
    position: relative;
    max-width: 500px;
   margin:30px;
    text-align: center;
  }
  
  .hr-lines:before{
    content:" ";
    height: 1px;
    width: 130px;
    background:black;
    display: block;
    position: absolute;
    top: 10%;
    left: 0;
  }
  
  .hr-lines:after{
    content:" ";
    height: 1px;
    width: 130px;
    background:black;
    display: block;
    position: absolute;
    top: 10%;
    right: 0;
  }
  
