
	*
    {
        margin:0px;
        padding:0px;
        box-sizing: border-box;
    }
		
	.toggle{
	position: relative;
    display: inline-block;
    width: 32px;
    height: 18px;
    /* background-color: red; */
    border-radius: 30px;
    border: 2px solid gray;
}
		
	/* After slide changes */
	.toggle:after {
		content: '';
		position: absolute;
		width: 12px;
		height: 13px;
		border-radius: 50%;
		background-color: gray;
		top: 1px;
		left: 1px;
		transition: all 0.5s;
	}
		
	/* Toggle text */
	
		
	/* Checkbox checked effect */
	.checkbox:checked + .toggle::after {
		left : 15px;
	}
		
	/* Checkbox checked toggle label bg color */
	.checkbox:checked + .toggle {
		background-color: #007780;
	}
		
	/* Checkbox vanished */
	.checkbox {
		display : none;
	}
	.smaller-logo {
		width: 20px; /* Adjust the width as needed */
		height: auto; /* Maintain the aspect ratio */
	}


/* Define the logo image size for full sidebar */
.dark-logo {
    width: 77px;
	height:auto;
	
    transition: width 0.3s; /* Add a smooth transition effect */
}

/* Adjust the logo image size for mini sidebar */
.mini-sidebar .dark-logo {
    width: 42px;
	height:auto;
}
.mini-sidebar .user-info
{
	display: none;
}

.mini-sidebar .user-profile
{
	width:2vw;
	margin-left:-12px;
}

@media(max-width:500px)
{
	.user-profile
	{
		width:10vw !important;
	}
}


