.vertical-radio-buttons
{
    display: flex;
    flex-direction: column; 
    align-items: flex-start !important;
}
.custom-title {
    /* Add your custom title styles here */
    font-size: 24px;
    color: #333;
    display: flex !important;
    margin-left:27px;  
}
.custom-confirm-button
{
    display: flex !important;
}
.gray-badge
{
    color:"gray"
}

