*
{
    margin:0px;
    padding:0px;
    box-sizing: border-box;
}
.custom-badge-style
{
    width: 93% !important;
    height: 114% !important;
}
.custom-badge-style1
{
    width:112px !important;
    height:34px !important;
}

.css-1p3m7a8-multiValue {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    min-width: 0;
    background-color: hsl(0, 0%, 90%);
    border-radius: 2px;
    margin: 2px;
    box-sizing: border-box;
    display: none;
}
label {
    display: flex ;
}

