@import url("https://fonts.googleapis.com/css?family=Bungee");

#un-wrapper {
    background: #ffffff;
    color: white;
    font-family: "Bungee", cursive;
    text-align: center;
    margin-top: 20vh;
}

#redirect {
    color: #2aa7cc;
    text-decoration: none;
}

#redirect:hover {
    /* color: #1b1b1b; */
    cursor: pointer;
}

#robot-error {
    width: 50vw;
}

.lightblue {
    fill: #444;
}

.eye {
    cx: calc(115px + 30px * var(--mouse-x));
    cy: calc(50px + 30px * var(--mouse-y));
}

#eye-wrap {
    overflow: hidden;
}

.error-text {
    font-size: 120px;
}

.alarm {
    animation: alarmOn 0.5s infinite;
}

@keyframes alarmOn {
    to {
        fill: darkred;
    }
}